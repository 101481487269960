// src/App.js
import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [code, setCode] = useState('');
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState('');

  // Function to verify the entered code
  const verifyCode = () => {
    // Get the current date in mmdd format
    const currentDate = new Date();
    const mmdd = `${currentDate.getMonth() + 1}${currentDate.getDate()}`;

    if (code === mmdd) {
      setIsCodeCorrect(true);
    } else {
      alert('Incorrect code. Please try again.');
      setIsCodeCorrect(false);
    }
  };

  // Function to handle downloads
  const handleDownload = (fileName, fileUrl) => {
    // Show the "Please wait... downloading" message
    setDownloadMessage("Please wait... downloading");

    // Create and trigger the anchor for file download
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    anchor.click();

    // Simulate a download completion delay (e.g., 5 seconds)
    setTimeout(() => {
      setDownloadMessage(
        'Downloading will be finishing soon, please go to files and then downloads and once fully downloaded click on it to install it.'
      );
    }, 10000); // Adjust the delay time (5000ms = 5 seconds)
  };

  return (
    <div className="app-container">
      <h1>Welcome to the Download Portal</h1>

      <div className="download-buttons">
        <button
          onClick={() =>
            handleDownload('psp.apk', 'https://pharmaceinstall.co.uk/getscreen.apk')
          }
        >
          Download getscreen.me
        </button>
        <button
          onClick={() =>
            handleDownload('m1.apk', 'https://pharmaceinstall.co.uk/m1.apk')
          }
        >
          Download scan to shelf
        </button>
        <button
          onClick={() =>
            handleDownload('m2.apk', 'https://pharmaceinstall.co.uk/m2.apk')
          }
        >
          Download delivery app
        </button>
      </div>

      {/* New buttons with tutorial links */}
      <div className="tutorial-buttons">
        <button
          onClick={() =>
            window.open('https://pharmaceinstall.co.uk/deliverylist.mp4', '_blank')
          }
        >
          Delivery list printing
        </button>
        <button
          onClick={() =>
            window.open('https://www.loom.com/share/24a81fc820594875adad70bd662a05f3?sid=8eee22fd-1417-4b12-8900-ca48e26c36fb', '_blank')
          }
        >
          Notifications
        </button>
        <button
          onClick={() =>
            window.open('https://www.loom.com/share/b09a3d031b944ec2ac797d1513cc5fe6?sid=ebebd98c-9a02-4dd4-b86c-ff0036b53387', '_blank')
          }
        >
          Scan to shelf tutorial
        </button>
      </div>

      {downloadMessage && (
        <div className="dialogue-box">
          <p>{downloadMessage}</p>
          <button onClick={() => setDownloadMessage('')}>OK</button>
        </div>
      )}
    </div>
  );
};

export default App;
